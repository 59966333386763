import React, { useState, useEffect } from "react"
import styled from "styled-components"

import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Highlighted from "../components/Highlighted"
import Adventures from "../components/Adventures"
import Cities from "../components/Cities"
import Movies from "../components/Movies"
import Photography from "../components/Photography"
import Navigation from "../components/Navigation"
import News from "../components/News"
import SEO from "../components/seo"
import Layout from "../layout/layout"

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 15px;
  width: 100%;

  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(5, 1fr);
    padding: 30px;
  }

  ${({ theme }) => theme.mq.xlg} {
    padding: 60px;
  }
`


const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  margin: 40px auto;

  ${({ theme }) => theme.mq.md} {
    padding: 0px 40px;
    margin: 70px auto;
    max-width: 90%;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 0px 60px;
    margin: 90px auto;
  }

  ${({ theme }) => theme.mq.xlg} {
    max-width: 80%;
  }
`

const BlogIndex = () => {
  const [showAnimation, setShowAnimation] = useState(true)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setTimeout(() => setShowAnimation(false), 4200)
    }
    mounted = false
  }, [])
  return (
    <>
      <SEO title="All posts" />
      <Layout showAnimation={showAnimation}>
        <Navigation />
          <Hero />
        <Wrapper>
          <News />
          <Adventures />
        </Wrapper>
        <Highlighted />
        <CategoryWrapper>
          <Cities />
          <Movies />
          <Photography />
        </CategoryWrapper>
        <Footer />
      </Layout>
    </>
  )
}

export default BlogIndex
