import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import usePostsRange from "../../hooks/usePostsRange"
import styled from "styled-components"

import Button from "../Common/Button"
import AdventuresItem from "../AdventuresItem/index"
import { Heading, GalleryWrapper } from "../News/index"
import { Wrapper } from "../Adventures/index"

const ExtendedWrapper = styled(Wrapper)`
  margin-bottom: 45px;

  & > div > a {
    margin: 35px 0px;
  }

  ${({ theme }) => theme.mq.md} {
    margin-bottom: 55px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 70px;
  }

  ${({ theme }) => theme.mq.xlg} {
    margin-bottom: 100px;
  }
`

const Cities = () => {
  const {
    blog: { posts },
  } = useStaticQuery(graphql`
    {
      blog {
        posts(where: { kategoria: Miasta }, orderBy: data_DESC) {
          id
          kategoria
          data
          naglowek1
          title
          opis1
          zdjecie_glowne {
            url
          }
        }
      }
    }
  `)

  const { handlePostsRange, increasePostsRange } = usePostsRange()
  return (
    <>
      <ExtendedWrapper>
        <Heading>Miasta</Heading>
        <GalleryWrapper>
          {posts &&
            handlePostsRange(posts).map(post => (
              <AdventuresItem key={post.id} post={post} />
            ))}
        </GalleryWrapper>

        <Button action={() => increasePostsRange(posts)}>Więcej</Button>
      </ExtendedWrapper>
    </>
  )
}

export default Cities
