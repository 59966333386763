import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Moment from "react-moment"

import {
  PostTitle,
  PostInfo,
  PostAuthor,
  AuthorIcon,
  AuthorName,
  PostDate,
  Category,
} from "../GalleryItem/index"
import DummyIcon from "../../../content/assets/dummyIcon.png"
const slugify = require("slugify")

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  padding: 6px;
  border-radius: 15px;
  ${({ bg }) =>
    bg &&
    `background: linear-gradient(180deg, #5e5e5e00 0%, #000000 100%) 0% 0% no-repeat, url(${bg}) no-repeat`};
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  height: 350px;
  width: 280px;
  transition: all 0.3s linear;

  ${({ theme }) => theme.mq.md} {
    padding: 14px;
    height: 450px;
    width: 320px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 20px;
    height: 500px;
    width: 350px;
  }

  ${({ theme }) => theme.mq.xlg} {
    height: 550px;
    width: 380px;
  }

  ${({ theme }) => theme.mq.xxlg} {
    height: 600px;
    max-width: 414px;
  }

  & > div {
    color: ${({ theme }) => theme.colors.white};
  }
`

const Info = styled(PostInfo)`
  margin: 0;
`

const Title = styled(PostTitle)`
  flex-grow: 0;
`


const HighlightedItem = ({
  post: { kategoria, zdjecie_glowne, title, data },
}) => {
  return (
    <Link to={title && slugify(title, { lower: true })}>
      <Card bg={zdjecie_glowne?.url}>
        <Category>{kategoria}</Category>
        <Title>{title}</Title>
        <Info>
          <PostAuthor>
            <AuthorIcon>
              <img src={DummyIcon} alt="Author Icon" />
            </AuthorIcon>
            <AuthorName>Grzegorz</AuthorName>
          </PostAuthor>

          <PostDate>
            <Moment format="DD MMM YYYY">{data && data}</Moment>
          </PostDate>
        </Info>
      </Card>
    </Link>
  )
}

export default HighlightedItem
