import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Container from "../../Container/index"
import Carousel from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"
import Photo from "../../../static/descriptionphoto.jpg"
import { Heading } from "../News/index"

const Wrapper = styled.div`
  display: flex;
  overflow: hidden;
  margin: 40px 0px;
  flex-direction: column;

  .BrainhubCarousel__container {
    overflow-x: hidden;
    height: 400px;
  }
  .BrainhubCarousel__trackContainer {
    overflow-x: hidden;
  }

  & > img {
    border-radius: 15px;
  }
`

const ImageWrapper = styled.div`
  border-radius: 15px;
  overflow: hidden;
`

const Img = styled.img`
  display: flex;
  padding: 12px;
  border-radius: 15px;
  max-width: 858px;
  max-height: 470px;
  flex: 1;
  width: 100%;
  height: 300px;
  margin-bottom: 30px;

  ${({ theme }) => theme.mq.sm} {
    margin: 0;
  }

  ${({ theme }) => theme.mq.md} {
    padding: 30px 40px;
    padding-top: 0px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 40px 50px;
    padding-top: 0px;
    height: 330px;
  }

  ${({ theme }) => theme.mq.xlg} {
    height: 350px;
  }
`

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.xxs};
  letter-spacing: 0.2px;

  & > div {
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > h2 {
      margin-bottom: 15px;
      text-align: center;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    width: 90%;
    flex-direction: row;
  }
`

const DescriptionPhoto = styled.img`
  width: 100%;
  max-height: 40vh;
  border-radius: 15px;
  object-fit: cover;

  ${({ theme }) => theme.mq.lg} {
    max-width: 30vw;
    height: auto;
  }
`

const Hero = () => {
  const {
    blog: { sliders },
  } = useStaticQuery(graphql`
    {
      blog {
        sliders {
          id
          photo {
            url
          }
          alternativeText
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <Carousel
          autoPlay={3500}
          animationSpeed={500}
          infinite
          arrows={false}
          draggable={false}
          slides={sliders.map(({ photo, alternativeText }) => (
            <ImageWrapper>
              <Img src={photo?.url} alt={alternativeText} key={photo.id} />
            </ImageWrapper>
          ))}
          breakpoints={{
            3000: {
              slidesPerPage: 3,
            },
            1440: {
              slidesPerPage: 2,
            },
            1024: {
              slidesPerPage: 1,
            },
            464: {
              slidesPerPage: 1,
            },
          }}
        ></Carousel>
      </Wrapper>
      <Description>
        <DescriptionPhoto src={Photo} alt="Description Photo" />
        <div>
          <Heading>O mnie</Heading>
          <p>
            Nazywam sie Grzesiek, pasja do motocykli towarzyszy mi od 12 roku
            życia i zaczęła sie od Wielkiej motorynki :). Pózniej jak każdy
            motocyklista przeszedłem przez wszystkie pojemności silnikowe i
            każdy rodzaj motocykla,by dziś w wieku 40+ zostać na stałe przy
            motocyklu turystycznym. Turystyka motocyklowa to moja pasja i
            marzenia..a marzenia są po to by je spełniać. Na mapie motocyklowych
            wypraw jest jeszcze wiele miejsc na świecie do zobaczenia a te
            największe marzenia są usytuowane w Azji i Afryce, dokąd mam
            nadzieje już niedługo razem pojedziemy. Po drodze do Azji napewno
            nie zabraknie jeszcze klilku państw europejskich,które koniecznie
            trzeba odhaczyć by zdobyć koronę najważniejszych motocyklowych tras
            :) Zapraszam do oglądania, czytania a znajomych - do wspólnych
            wypraw :) Carpe diem.
          </p>
        </div>
      </Description>
    </Container>
  )
}

export default Hero
