import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Moment from "react-moment"

import DummyImage from "../../../content/assets/dummyImage.png"
import DummyIcon from "../../../content/assets/dummyIcon.png"
import {
  PostTitle,
  PostDescription,
  PostInfo,
  PostAuthor,
  AuthorIcon,
  AuthorName,
  PostDate,
} from "../GalleryItem/index"
import { CategoryBox } from "../CategoryBox/index"
const slugify = require("slugify")

const CardLink = styled(Link)`
  display: block;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  transition: all 0.3s linear;
  border-radius: 15px;
  padding: 7px;

  ${({ theme }) => theme.mq.sm} {
    flex-direction: row;
    padding: 14px;
  }

  &:hover {
    box-shadow: 3px 3px 40px #00000029;
  }
`

const ImageBox = styled.div`
  display: flex;
  max-height: 300px;
  margin-bottom: 10px;
  flex: 1;

  img {
    flex: 1;
    height: 180px;
    border-radius: 15px;
    object-fit: cover;
    min-width: 100%;
  }

  ${({ theme }) => theme.mq.sm} {
    margin-top: 0px;
    margin-right: 15px;
    margin-bottom: 0px;

    img {
      height: 240px;
    }
  }

  ${({ theme }) => theme.mq.md} {
    margin-right: 30px;
    max-width: 414px;
    & > img {
      height: 300px;
    }
  }
`

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  flex: 1;

  & > p {
    max-width: 100%;
    width: 100%;
    word-break: break-word;
  }
`

const Title = styled(PostTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 1rem 0;
  flex-grow: 0;

  ${({ theme }) => theme.mq.lg} {
    font-size: 22px;
  }
`

const Description = styled(PostDescription)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.4;
  margin-bottom: 15px;
  font-size: 1.4rem;

  ${({ theme }) => theme.mq.sm} {
    font-size: 1.6rem;
  }
`

const PostAuthorInfo = styled(PostInfo)`
  margin-top: auto;
`
const AdventuresItem = ({ post }) => {
  const {
    zdjecie_glowne: { url },
    kategoria,
    data,
    opis1,
    title,
  } = post
  return (
    <CardLink to={`/${title && slugify(title, { lower: true })}`}>
      <Card>
        <ImageBox>
          <img src={url ? url : DummyImage} alt="Post Preview" />
        </ImageBox>
        <DescriptionBox>
          <CategoryBox>{kategoria ? kategoria : "Lorem"} </CategoryBox>
          <Title>{title ? title : "Lorem"}</Title>

          <Description>{opis1 ? opis1 : "Lorem ipsum lorem ipsum"}</Description>

          <PostAuthorInfo>
            <PostAuthor>
              <AuthorIcon>
                <img src={DummyIcon} alt="Author Icon" />
              </AuthorIcon>
              <AuthorName>Grzegorz</AuthorName>
            </PostAuthor>

            <PostDate>
              <Moment format="DD MMM YYYY">{data && data}</Moment>
            </PostDate>
          </PostAuthorInfo>
        </DescriptionBox>
      </Card>
    </CardLink>
  )
}

export default AdventuresItem
