import React from "react"
import styled from "styled-components"

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: fit-content;
  padding: 5px 12px;
  background: ${({ theme }) => theme.gradient.defaultBlueGradient};
  font-size: ${({ theme }) => theme.font.size.md};
  border: none;
  cursor: pointer;
  align-self: center;
  outline: 2px solid transparent;
  margin: 10px 0px;

  > span {
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    background-image: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradient.defaultBlueGradient} 0%,
      ${({ theme }) => theme.gradient.defaultBlueGradient} 50%,
      ${({ theme }) => theme.colors.vividBlue} 50%,
      ${({ theme }) => theme.colors.vividBlue} 100%
    );
    background-repeat: repeat;
    background-size: 200%;
    background-position: 100% 0;
    -webkit-background-clip: text;
    background-clip: text;
    transition: background-position 300ms;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.white};
    transform-origin: 100% 0;
    transform: scale3d(0, 1, 1);
    transition: transform 300ms;
  }

  &:hover,
  :active {
    outline: 2px solid #6aa4ff;
    color: ${({ theme }) => theme.colors.vividBlue};
    span {
      background-position: 0 0;
      color: ${({ theme }) => theme.colors.vividBlue};
    }

    &::before {
      transform-origin: 0 0;
      transform: scale3d(1, 1, 1);
    }
  }

  &:focus {
    outline: 2px solid ${({  theme  }) => theme.colors.black};;
  }
`

const index = ({ children, action }) => {
  return (
    <Button onClick={action}>
      <span>{children}</span>
    </Button>
  )
}

export default index
