import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import usePostsRange from "../../hooks/usePostsRange"

import Button from "../Common/Button"
import AdventuresItem from "../AdventuresItem/index"
import { Heading, GalleryWrapper } from "../News/index"


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  grid-column: 1/2;

  & > h2 {
    text-align: center;
  }

  & > a {
    margin: 25px auto;
  }

  ${({ theme }) => theme.mq.sm} {
    margin-top: 75px;

    & > a {
      margin: 20px 0px;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    margin-top: 0;
    grid-column: 3/6;
  }
`

const Adventures = () => {
  const {
    blog: { posts },
  } = useStaticQuery(graphql`
    {
      blog {
        posts(where: { kategoria: Wyprawy }, orderBy: data_DESC) {
          id
          kategoria
          data
          naglowek1
          title
          opis1
          zdjecie_glowne {
            url
          }
        }
      }
    }
  `)

    const { handlePostsRange, increasePostsRange } = usePostsRange()
  return (
    <>
      <Wrapper>
        <Heading>Wyprawy</Heading>
        <GalleryWrapper>
          {posts &&
            handlePostsRange(posts).map(post => (
              <AdventuresItem key={post.id} post={post} />
            ))}
        </GalleryWrapper>

        <Button action={() => increasePostsRange(posts)}>Więcej</Button>
      </Wrapper>
    </>
  )
}

export default Adventures
