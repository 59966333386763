import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Button from "../Common/Button"
import Gallery from "../Gallery"
import usePostsRange from "../../hooks/usePostsRange"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  grid-column: 1;

  ${({ theme }) => theme.mq.lg} {
    grid-column: 1/3;
  }
`

export const Heading = styled.h2`
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

export const GalleryWrapper = styled.div`
  max-height: 1730px;
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 5px;

  ${({ theme }) => theme.mq.md} {
    padding: 0 10px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 0 15px;
  }
`

const News = () => {
  const {
    blog: { posts },
  } = useStaticQuery(graphql`
     {
      blog {
        posts(where: { kategoria: Aktualnosci }, orderBy: data_DESC) {
          id
          title
          kategoria
          opis1
          data
          autor
          zdjecie_glowne {
            url
          }
        }
      }
    }
  `)
  const { handlePostsRange, increasePostsRange } = usePostsRange()

  return (
    <Wrapper>
      <Heading>Aktualności</Heading>
      <GalleryWrapper isMainPage={true}>
        <Gallery isNews={true} posts={handlePostsRange(posts)} />
      </GalleryWrapper>
      <Button action={() => increasePostsRange(posts)}>Więcej</Button>
    </Wrapper>
  )
}

export default News
