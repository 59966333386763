import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { Heading } from "../News/index"
import Container from "../../Container/index"
import HighlightedItem from "../HighlightedItem"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 0px;

  ${({ theme }) => theme.mq.md} {
    padding: 25px 0px;
  }
`

const PostsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 300px));
  grid-gap: 20px;
  place-items: center;
  justify-content: center;
  max-width: 1726px;

  ${({ theme }) => theme.mq.sm} {
    justify-content: space-between;
    grid-gap: 32px;
  }

  ${({ theme }) => theme.mq.md} {
    grid-template-columns: repeat(auto-fit, minmax(100px, 368px));
  }

  & > a {
    transition: 0.3s all linear;
    border-radius: 15px;

    &:hover {
      box-shadow: -1px 0px 20px 5px #00000029;
    }
  }
`

const Highlighted = () => {
  const {
    blog: { posts },
  } = useStaticQuery(graphql`
    query {
      blog {
        posts(where: { wyroznione: true }, orderBy: data_DESC) {
          id
          title
          kategoria
          data
          autor
          zdjecie_glowne {
            url
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Wrapper>
        <Heading>Wyróżnione posty</Heading>
      </Wrapper>
      <Container>
        <PostsWrapper>
          {posts.map(post => (
            <HighlightedItem key={post.id} post={post} />
          ))}
        </PostsWrapper>
      </Container>
    </Container>
  )
}

export default Highlighted
