import { useState } from "react"

const usePostsRange = () => {
  const startRange = 1
  const [endRange, setEndRange] = useState(4)

  const handlePostsRange = postsArr => {
    return postsArr.reverse().slice(startRange - 1, endRange)
  }

  const increasePostsRange = postsArr => {
    if (postsArr.length < endRange || endRange + 4 > postsArr.length) {
      setEndRange(postsArr.length)
      return
    }
    setEndRange(endRange + 2)
  }

  return { increasePostsRange, handlePostsRange }
}

export default usePostsRange
